footer {
  background-color: var(--clr-dark);
  text-align: center;
}

.icons-div {
  display: block;
}

.footer-p {
  display: block;
}

.icon {
  margin: 0.5em;
}
