.main-container {
  background-color: var(--clr-dark);
  padding: 5%;
}

.section-heading {
  color: var(--clr-light);
  -webkit-text-stroke: 0.1px var(--clr-accent);
  font-size: var(--fs-h1);
  font-family: var(--ff-primary);
  font-weight: bolder;
  text-align: center;
}

/*** About Section ***/
.about-section {
}

.about-title {
  text-align: center;
  margin: 0;
  font-size: var(--fs-h3);
  font-family: var(--ff-primary);
}

.about-p {
  padding: 0.5em;
  text-indent: 1.8em;
}

/*** Core Values ***/
.core-values-section {
  margin-top: 30px;
}

.core-values-subtitles {
  color: var(--clr-light);
  -webkit-text-stroke: 0.1px var(--clr-accent);
  font-size: var(--fs-h2);
  font-family: var(--ff-primary);
  font-weight: bolder;
}

.value-container {
  height: 15em;
  width: 100%;
  text-align: center;
  border: solid 1px black;
  margin: auto;
  margin-bottom: 20px;
  display: block;
  box-shadow: 3px 3px 3px 3px white;
}

/*** Larger Screens ***/

@media screen and (min-width: 575px) {
  .core-values-section article {
    display: grid;
    grid-template-areas:
      'topleft-value topright-value'
      'bottomleft-value bottomright-value';
    gap: 20px;
    max-width: 1000px;
    margin: auto;
  }

  .value-container {
    width: 70%;
    max-width: 100%;
  }

  .about-section {
    max-width: 800px;
    margin: auto;
  }

  .about-p {
    margin: 1em;
  }
}
