.project-container {
  width: 100%;
  padding: 20px;
  margin: auto;
  background-color: var(--clr-dark);
}

.project-title {
  text-align: center;
  padding: 20px;
  margin: 0;
  color: var(--clr-light);
  -webkit-text-stroke: 0.1px var(--clr-accent);
  font-size: var(--fs-h2);
  font-family: var(--ff-primary);
  font-weight: bolder;
}

.image-container {
  text-align: center;
}

.project-image {
  width: 100%;
  margin: auto;
}

.details-container {
  text-align: center;
}

.project-description {
  padding-top: 15px;
  text-indent: 1.8em;
}

@media screen and (min-width: 500px) {
  .project-container {
    padding: 5em;
  }

  .project-deets-div {
    display: flex;
    flex-direction: row;
  }

  .project-title {
  }

  .project-image {
    width: 50%;
    height: 100%;
    max-width: 300px;
    max-height: 500px;
  }

  .project-description {
    margin: 2em;
  }
}

@media screen and (min-width: 900px) {
  .project-container {
    padding: 1em 10em;
    max-width: 1100px;
  }
}
