*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
/* Custom CSS Properties */

:root {
  --ff-primary: 'Girassol', cursive;
  --ff-secondary: 'Roboto Condensed', sans-serif;

  --clr-light: #f4fffd;
  --clr-dark: rgb(27, 23, 23);
  --clr-accent: #89c4ff;
  --clr-accent2: #484041;

  --fs-h1: 3rem;
  --fs-h2: 2rem;
  --fs-h3: 1.55rem;
  --fs-h4: 0.9rem;
  --fs-body: 1rem;

  --bs: 0.25em 0.25em 0.75em rgb(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgb(0, 0, 0, 0.15);
}

#root {
  background-color: var(--clr-dark);
}

body {
  background-color: var(--clr-dark);
}

h1 {
  font-family: var(--ff-primary);
}

p {
  font-family: var(--ff-secondary);
  color: white;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
